import React from "react";
import Footer from "./Footer";
import Header from "./Header";

export const PrivacyPolicyMobile = () => {
  // Inline styles as JavaScript objects
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "left",
    marginBottom: "200px",
  };

  const titleStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
    textAlign: "center",
  };

  const sectionTitleStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    margin: "20px 0 10px",
    color: "#444",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#555",
    textAlign: "justify",
    marginBottom: "10px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Privacy Policy</h2>
      <p style={paragraphStyle}>Last modified: November 2024</p>
      <h3 style={sectionTitleStyle}>Opt-out options: DO NOT USE MY DATA</h3>
      <p style={paragraphStyle}>
        I. INTRODUCTION. REGIONAL PATTERNS (CALIFORNIA)
      </p>
      <p style={paragraphStyle}>
        I&U Smart Tech LLC (“we,” “us” or “our”) takes your privacy seriously.
        This Privacy policy (“Privacy policy”) explains our data protection
        policy and describes the types of information we may process when you
        install and/or use the "InaayaAI" software application for mobile
        devices (the “App”, “our App”).
      </p>

      <p style={paragraphStyle}>
        PLEASE READ THE FOLLOWING PRIVACY POLICY, FOR INFORMATION REGARDING THE
        WAYS YOUR PERSONAL INFORMATION MAY BE PROCESSED, CAREFULLY.
      </p>

      <h3 style={sectionTitleStyle}>California Residents</h3>
      <p style={paragraphStyle}>
        Under the California Consumer Privacy Act of 2018 ("CCPA") California
        residents shall have the right to request:
      </p>
      <ul>
        <li style={paragraphStyle}>
          The categories of personal information that is processed.
        </li>
        <li style={paragraphStyle}>
          The categories of sources from which personal information is obtained.
        </li>
        <li style={paragraphStyle}>
          The purpose for processing of user personal data.
        </li>
        <li style={paragraphStyle}>
          The list and categories of third parties with whom we may share your
          personal information.
        </li>
      </ul>

      <h3 style={sectionTitleStyle}>Personal Information</h3>
      <p style={paragraphStyle}>
        Information on the categories of information, its sources, and purposes
        of processing.
      </p>

      <h3 style={sectionTitleStyle}>Sharing</h3>
      <p style={paragraphStyle}>
        Information on how your information can be shared.
      </p>

      <h3 style={sectionTitleStyle}>Opt-out Options</h3>
      <p style={paragraphStyle}>
        If you don't want us to process your personal information anymore,
        please contact us through the contact form.
      </p>

      <h3 style={sectionTitleStyle}>Requests</h3>
      <p style={paragraphStyle}>
        To submit a verifiable consumer request for access, portability or
        deletion of personal data please contact us through the contact form.
      </p>

      <h3 style={sectionTitleStyle}>Equal Rights</h3>
      <p style={paragraphStyle}>
        Nothing in the way we deal with your request shall be interpreted as
        discrimination.
      </p>

      <h3 style={sectionTitleStyle}>Sale of Data</h3>
      <p style={paragraphStyle}>
        We do not sell any of your personal data to third parties.
      </p>

      {/* Continue to add other sections in similar format */}

      <h3 style={sectionTitleStyle}>How to Contact Us</h3>
      <p style={paragraphStyle}>
        If you have any questions about this Privacy policy, please contact us
        via email at support@inaayaai.com
      </p>
    </div>
  );
};
