import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TermsAndConditions } from "./TermsAndConditions";
import { PrivacyPolicyMobile } from "./PrivacyPolicyMobile";
import { TermsAndConditionsMobile } from "./TermsAndConditionsMobile";
import { LandingPage } from "./Landing";


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/mobile/privacy-policy" element={<PrivacyPolicyMobile />} />
        <Route path="/mobile/terms-and-conditions" element={<TermsAndConditionsMobile />} />
      </Routes>
    </Router>
  );
};

export default App;
