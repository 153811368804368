import React from "react";
import { Link } from "react-router-dom";
import SmartTechIcon from "./assets/smarttech-icon.png";

const Header = () => {
  return (
    <header className="w-full bg-gradient-to-r from-[#282b2f] to-[#5a4fcf] text-white shadow-md sticky top-0 z-50">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8 flex justify-between items-center py-6">
        {/* Logo Section */}
        <Link
          to="/"
          className="flex items-center text-white no-underline space-x-3"
        >
          {/* <img
            src={SmartTechIcon}
            alt="SmartTech Logo"
            className="w-8 h-8 md:w-10 md:h-10 object-contain"
          /> */}
          <h1 className="md:text-xl font-black uppercase tracking-wider">
            I&U Smart Tech
          </h1>
        </Link>

        {/* Contact Information */}
        <div className="text-sm md:text-base text-white font-semibold text-right">
          Contact us at{" "}
          <a
            href="mailto:support@iusmarttech.com?subject=Support Request&body=Hi Support Team,"
            className="underline hover:text-[#f0e130] transition-colors"
          >
            support@iusmarttech.com
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
