import React from "react";
import Footer from "./Footer";
import Header from "./Header";

export const TermsAndConditionsMobile = () => {
  // Inline styles as JavaScript objects
  const containerStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "20px",
    textAlign: "left",
    marginBottom: "200px",
  };

  const titleStyle = {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#333",
    textAlign: "center",
  };

  const sectionTitleStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    margin: "20px 0 10px",
    color: "#444",
  };

  const paragraphStyle = {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#555",
    textAlign: "justify",
    marginBottom: "10px",
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Terms of Use and End User License Agreement</h2>
      <p style={paragraphStyle}>Last updated: November 2024</p>

      <h3 style={sectionTitleStyle}>I. Introduction</h3>
      <p style={paragraphStyle}>
        These Terms of Use and End User License Agreement (collectively, the
        “Agreement”) together with all referenced documents constitute a legally
        binding agreement between you and I&U Smart Tech LLC (“we,” “us” or
        “our”) regarding your access to and use of the “InaayaAI” software
        application for mobile devices (the “App”).
      </p>

      <h3 style={sectionTitleStyle}>II. Changes to This Agreement</h3>
      <p style={paragraphStyle}>
        We reserve the right, at our sole discretion, to make changes or
        modifications to this Agreement at any time and for any reason.
      </p>

      <h3 style={sectionTitleStyle}>
        III. Restrictions on Who Can Use the App
      </h3>
      <p style={paragraphStyle}>
        In order to use the App, you must be of the legal age in your
        jurisdiction (generally 18 or older) or have the consent of a parent or
        guardian.
      </p>

      <h3 style={sectionTitleStyle}>IV. General Terms</h3>
      <p style={paragraphStyle}>
        The App is developed for educational purposes, utilizing AI technologies
        to support your language learning experience. However, the App does not
        guarantee reaching a specific language proficiency level.
      </p>

      <h3 style={sectionTitleStyle}>V. Privacy Policy</h3>
      <p style={paragraphStyle}>
        Your privacy is very important to us. Please refer to our Privacy Policy
        to understand how we handle and process your data. By using the App, you
        agree to our Privacy Policy.
      </p>

      <h3 style={sectionTitleStyle}>VI. End User License Agreement</h3>
      <p style={paragraphStyle}>
        By using the App, you agree to respect our intellectual property rights
        as well as those of third parties. You are granted a limited,
        non-exclusive, and revocable license to use the App solely for personal,
        non-commercial purposes.
      </p>

      <h3 style={sectionTitleStyle}>VII. Prohibited Behavior</h3>
      <p style={paragraphStyle}>
        Users are prohibited from using the App in ways that are illegal,
        offensive, infringe on intellectual property rights, or violate the
        rights of others.
      </p>

      <h3 style={sectionTitleStyle}>
        VIII. Availability of the App, Security, and Accuracy
      </h3>
      <p style={paragraphStyle}>
        We do not guarantee uninterrupted access to the App, and availability
        may be affected by factors outside our control.
      </p>

      <h3 style={sectionTitleStyle}>IX. Charges</h3>
      <p style={paragraphStyle}>
        The App is free to download. However, certain features require a paid
        subscription, which will auto-renew unless canceled. Review your
        subscription plan and trial period before purchasing.
      </p>

      <h3 style={sectionTitleStyle}>X. Third-Party Websites and Resources</h3>
      <p style={paragraphStyle}>
        The App may contain links to third-party sites or services. We do not
        control and are not responsible for the content of these third-party
        websites or services.
      </p>

      <h3 style={sectionTitleStyle}>XI. Disclaimer of Warranties</h3>
      <p style={paragraphStyle}>
        The App and its services are provided "as is" without warranties of any
        kind, including, but not limited to, fitness for a particular purpose.
      </p>

      <h3 style={sectionTitleStyle}>XII. Limitation of Liability</h3>
      <p style={paragraphStyle}>
        We are not liable for any damages arising from your use of the App or
        its services, including incidental, special, or punitive damages.
      </p>

      <h3 style={sectionTitleStyle}>XIII. Legal Compliance</h3>
      <p style={paragraphStyle}>
        You must comply with all applicable laws, and you represent that you are
        not located in a restricted country or on any prohibited list.
      </p>

      <h3 style={sectionTitleStyle}>XIV. Governing Law and Claims</h3>
      <p style={paragraphStyle}>
        This Agreement is governed by the laws of the state of Florida, USA.
        Disputes will be resolved under Florida jurisdiction.
      </p>

      <h3 style={sectionTitleStyle}>XV. Termination</h3>
      <p style={paragraphStyle}>
        We reserve the right to terminate this Agreement at any time for any
        reason.
      </p>

      <h3 style={sectionTitleStyle}>XVI. Severability</h3>
      <p style={paragraphStyle}>
        If any provision of this Agreement is found invalid or unenforceable, it
        shall be modified or removed as necessary.
      </p>

      <h3 style={sectionTitleStyle}>XVII. Contact Information</h3>
      <p style={paragraphStyle}>
        If you have questions about this Agreement or the App, please contact us
        via email at support@inaayaai.com
      </p>
    </div>
  );
};
